import {
    productDetail_productDetail_items,
    productDetail_productDetail_items_image,
    productDetail_productDetail_items_labels,
    productDetail_productDetail_items_media_gallery,
    productDetail_productDetail_items_media_gallery_ProductVideo,
} from 'src/queries/__generated__/productDetail';
import { MediaEntry, ProductPrice, ProductType, StockStatus } from 'src/types/global-types';
import { imagePath } from 'src/util/formatUrl';
import { Breadcrumb, cleanBreadcrumbs } from 'src/components/Breadcrumbs/breadcrumb.utils';
import { productName } from 'src/util/productName';
import { SingleLabel } from 'src/components/ProductLabels/ProductLabel';
import { cleanLabelData } from 'src/components/ProductLabels/utils/ProductLabelData';

export interface BaseProduct {
    blog_ids: string[];
    breadcrumbs: Breadcrumb[];
    description: string | null;
    energy_label_image: string | null;
    energy_rating: string | null;
    id: string;
    labels: SingleLabel[];
    media_entries: MediaEntry[];
    meta_description: string;
    meta_title: string;
    name: string;
    product_fiche_image: string | null;
    short_description: string | null;
    sku: string;
    small_image: string;
    type: ProductType;
    url_key: string | null;
}

export type Product = BaseProduct;

export enum DeliveryType {
    DELIVERY_HOME = 'DELIVERY_HOME',
    DELIVERY_DROPSHIP = 'DELIVERY_DROPSHIP',
    DELIVERY_CENTRE = 'DELIVERY_CENTRE',
}

export interface BundleProduct extends BaseProduct {
    price: ProductPrice;
}

export const EMPTY_PRODUCT: Product = {
    blog_ids: [],
    breadcrumbs: [],
    description: '',
    energy_label_image: '',
    energy_rating: '',
    id: '-1',
    labels: [],
    media_entries: [],
    meta_description: '',
    meta_title: '',
    name: '',
    product_fiche_image: '',
    short_description: '',
    sku: '',
    small_image: 'https://placehold.it/200/ffffff',
    type: ProductType.SimpleProduct,
    url_key: null,
};

export const getDeliveryType = (delivery_type?: number | null) => {
    switch (delivery_type) {
        case 1:
            return DeliveryType.DELIVERY_HOME;

        case 2:
            return DeliveryType.DELIVERY_CENTRE;

        case 3:
            return DeliveryType.DELIVERY_DROPSHIP;

        default:
            return undefined;
    }
};

export function cleanBaseProduct<T extends productDetail_productDetail_items>(first: T | undefined): BaseProduct {
    if (!first) {
        return EMPTY_PRODUCT;
    }

    const blog_ids = (first.woodies?.blog_ids ?? []).filter((value) => value !== null) as BaseProduct['blog_ids'];

    const name = productName(first.name || 'unknown');

    return {
        blog_ids,
        breadcrumbs: cleanBreadcrumbs(first.breadcrumbs),
        description: first.description?.html || null,
        energy_label_image: first.woodies?.energy_label_image || null,
        energy_rating: first.woodies?.energy_rating || null,
        id: String(first.id!),
        labels: cleanLabelData<productDetail_productDetail_items_labels>(first.labels),
        media_entries: cleanMediaEntries(
            (first.media_gallery as productDetail_productDetail_items_media_gallery[]) || [],
            first.image as productDetail_productDetail_items_image,
        ),
        meta_description: first.meta_description || first.short_description?.html || name,
        meta_title: first.meta_title || name,
        name,
        product_fiche_image: first.woodies?.product_fiche_image || null,
        short_description: first.short_description?.html || '',
        sku: first.sku || 'unknown',
        small_image: imagePath(first.small_image?.url),
        type: ProductType[first.__typename],
        url_key: first.url_key,
    };
}

export function cleanProduct(first: productDetail_productDetail_items | undefined): Product {
    if (!first) {
        return EMPTY_PRODUCT;
    }

    const base = cleanBaseProduct(first);
    return base;
}

export function productPrice<T extends { price_saving: string | null; price_per: string | null }>(
    regularPrice: number,
    specialPrice: number | null,
    attrsInput: T | null | undefined,
): ProductPrice {
    const attrs = attrsInput || ({} as Record<string, any>);
    if (specialPrice && specialPrice !== regularPrice) {
        return {
            regular_price: regularPrice,
            price: specialPrice,
            price_incl: specialPrice,
            discount: attrs.price_saving,
            price_per: attrs.price_per,
        };
    }
    return {
        price_incl: regularPrice,
        price: regularPrice,
        price_per: attrs.price_per,
    };
}

const DEFAULT_IMAGE: MediaEntry = {
    large_height: 1200,
    large_width: 1200,
    large_url: 'https://placehold.it/1200/ffffff',
    medium_url: 'https://placehold.it/500/ffffff',
    small_url: 'https://placehold.it/200/ffffff',
    media_type: 'image',
    position: 0,
    label: 'image',
    hidden: false,
};

export function cleanMediaEntries(
    media: productDetail_productDetail_items_media_gallery[],
    firstImage: productDetail_productDetail_items_image,
): MediaEntry[] {
    if (!media || !Array.isArray(media)) return [DEFAULT_IMAGE];

    const firstGalleryImage = media.find((m) => m.url === firstImage.url);

    const orderedMedia = firstGalleryImage
        ? [firstGalleryImage, ...media.filter((m) => m.url !== firstImage.url)]
        : media;

    const cleanedOrderedMedia: MediaEntry[] = [];

    orderedMedia.forEach((item, index) => {
        const videoItem = item as productDetail_productDetail_items_media_gallery_ProductVideo;
        const large = item.url;
        const medium = item.medium_url;
        const small = item.small_url;

        cleanedOrderedMedia.push({
            large_height: item.large_height || 1200,
            large_width: item.large_width || 1200,
            large_url: imagePath(large),
            medium_url: imagePath(medium),
            small_url: imagePath(small),
            media_type: 'image',
            position: item.position || index,
            label: item.label || 'unknown',
            hidden: item.disabled || false,
            video_content: videoItem.video_content
                ? {
                      media_type: videoItem.video_content?.media_type || undefined,
                      video_provider: videoItem.video_content?.video_provider || undefined,
                      video_url: videoItem.video_content?.video_url || undefined,
                      video_title: videoItem.video_content?.video_title || undefined,
                      video_description: videoItem.video_content?.video_description || undefined,
                      video_metadata: videoItem.video_content?.video_metadata || undefined,
                  }
                : undefined,
        });
    });

    return cleanedOrderedMedia;
}

export type ConfigurableAttributeOption = {
    code: string;
    label: string;
    value_index: number;
};

export interface BaseVariant {
    sku: string;
    id: number;
    attributes: ConfigurableAttributeOption[];
    size_id: number;
    color_id: number;
    stock_status: StockStatus;
    max_qty: number | null;
}

export interface ProductVariant extends BaseVariant {
    media_entries: MediaEntry[];
    small_image: string;
}

export interface Specification {
    label: string;
    value: string;
}

export interface DataSheet {
    filename: string;
    url: string;
    attribute_code: string;
}
